import React, { useEffect, useContext } from "react";
import Layout from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";
import ProjectGrid from "../components/ProjectGrid";
import ContactUsBlock from "../components/ContactUsBlock";
import Seo from "../components/seo";
import InViewLogoAnimationController from "../components/InViewLogoAnimationController";
import { LogoAnimationContext } from "../context/LogoAnimationContext";
import Vimeo from "@u-wave/react-vimeo";
import ogIMage from "../images/og.png";

const DivisionDetail = ({ data, location }) => {
    const schema = [
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Sedin Technologies",
            url: "https://sedintechnologies.com/",
            sameAs: [
                "https://www.facebook.com/SedinTechnologies",
                "https://twitter.com/SedinTech",
                "https://www.instagram.com/lifeatsedin/",
                "https://www.linkedin.com/company/sedin-technologies/",
            ],

            address: {
                "@type": "PostalAddress",
                addressLocality: "Chennai",
                postalCode: "600083",
                streetAddress:
                    "# A1/1, 49th Street, 7th Avenue, Ashok Nagar, Chennai, TN, India, 600083",
            },
        },
        {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
                {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home page",
                    item: "https://sedintechnologies.com/",
                },
                {
                    "@type": "ListItem",
                    position: 2,
                    name: "Divisions",
                    item: "https://sedintechnologies.com/divisions/",
                },
                {
                    "@type": "ListItem",
                    position: 3,
                    name: data.pageData.name,
                    item: `https://sedintechnologies.com${location.pathname}`,
                },
            ],
        },
    ];
    const { setCurrentAnimationName, currentAnimationName } = useContext(LogoAnimationContext);
    const Services = data.pageData.services ? data.pageData.services : null;
    const DivisionMembers = data.pageData.members ? data.pageData.members : null;
    const seoTitle = data.pageData.metaTitle ? data.pageData.metaTitle : data.pageData.title;
    const seoDescription = data.pageData.metaDescription
        ? data.pageData.metaDescription.metaDescription
        : data.pageData.description.description;

    const divisonsOgImage = data.pageData.ogImage.gatsbyImageData?.images.fallback.src
        ? data.pageData.ogImage.gatsbyImageData?.images.fallback.src
        : ogIMage;

    useEffect(() => {
        if (currentAnimationName !== "division_transition") {
            setCurrentAnimationName("division_transition");
        }
        return () => {
            setCurrentAnimationName("division_transition");
        };
    }, []);

    return (
        <Layout>
            <Seo
                schemaMarkup={schema}
                title={seoTitle}
                description={seoDescription}
                url={location.href}
                image={divisonsOgImage}
            />
            <section className="hero-division-details">
                <div className="container container--py">
                    <div className="logo gap-2x">
                        {data.pageData.logo.gatsbyImageData ? (
                            <GatsbyImage
                                image={data.pageData.logo.gatsbyImageData}
                                alt={data.pageData.logo.title}
                                loading="lazy"
                            />
                        ) : (
                            <img
                                src={data.pageData.logo.file.url}
                                alt={data.pageData.logo.title}
                                loading="lazy"
                            />
                        )}
                    </div>
                    <div className="hero-text">
                        <h1 className="text-h2 text-fw-medium text-clr-primary gap-3x">
                            {data.pageData.description.description}
                        </h1>
                    </div>
                    <div className="footer">
                        <div className="team">
                            {DivisionMembers &&
                                DivisionMembers.map((m) => (
                                    <span key={m.id}>
                                        <GatsbyImage
                                            image={m.avatar.gatsbyImageData}
                                            alt={m.avatar.title}
                                            loading="lazy"
                                        />
                                    </span>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
            {data.pageData.featuredVideo && (
                <section className="division-video">
                    <div className="container container--py">
                        <InViewLogoAnimationController animationName={"play"}>
                            <Vimeo
                                height="684"
                                video={data.pageData.featuredVideo}
                                responsive={true}
                            />
                        </InViewLogoAnimationController>
                    </div>
                </section>
            )}
            {Services && (
                <section className="divisions-detail-services">
                    <div className="container container--last">
                        <div className="divions-services-grid">
                            <div className="a-title-col1">
                                <h2 className="text-h1 text-fw-medium text-clr-primary">
                                    Services
                                </h2>
                            </div>
                            <div className="a-details-cnt-col2">
                                {Services.map((s) => (
                                    <div key={s.id} className="service-item">
                                        <p className="text-p1 text-fw-medium text-clr-primary a-gap-1x">
                                            {s.title}
                                        </p>
                                        <div className="text-p2 text-fw-regular text-clr-secondary">
                                            {s.description.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <section className="details-glance">
                <div className="container container--py">
                    <div className="a-glance-grid">
                        <div className="a-glance-col1">
                            <h2 className="text-h2 text-fw-medium text-clr-primary">At a glance</h2>
                        </div>
                        <div className="a-glance-col2">
                            <div className="a-glance-number-wrp">
                                <p className="text-p3 text-fw-regular text-clr-secondary">People</p>
                                <div className="text-number text-clr-primary">
                                    {data.pageData.people}
                                </div>
                            </div>
                            <div className="a-glance-number-wrp">
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Offices
                                </p>
                                <div className="text-number text-clr-primary">
                                    {data.pageData.offices}
                                </div>
                            </div>
                            <div className="a-glance-number-wrp">
                                <p className="text-p3 text-fw-regular text-clr-secondary">
                                    Countries
                                </p>
                                <div className="text-number text-clr-primary">
                                    {data.pageData.countries}
                                </div>
                            </div>
                            <div className="a-glance-number-wrp">
                                <p className="text-p3 text-fw-regular text-clr-secondary">Years</p>
                                <div className="text-number text-clr-primary">
                                    {data.pageData.years}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="glance-case-studies">
                <div className="container container--mid">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-4x">
                            Case studies
                        </h2>
                    </div>
                    <div className="gap-3x">
                        <ProjectGrid CaseStudiesData={data.pageData.casestudies} />
                    </div>
                    {data.pageData.website && (
                        <a
                            href={data.pageData.website}
                            target="_blank"
                            rel="noreferrer"
                            className="sdn-link"
                        >
                            Visit {data.pageData.name} website
                        </a>
                    )}
                </div>
            </section>
            <section className="about-contact">
                <div className="container container--last">
                    <ContactUsBlock />
                </div>
            </section>
            <section className="other-divisions">
                <div className="container container--py">
                    <div className="other-divisons-grid">
                        <div className="other-divisions-col1">
                            <h2 className="text-h2 text-fw-medium text-clr-primary">
                                Other divisions
                            </h2>
                        </div>
                        <div className="other-divisions-col2">
                            {data.otherDivisions.nodes.map((d) => (
                                <div key={d.id} className="divisions-logo">
                                    <Link to={`/divisions/${d.slug}/`}>
                                        <span>
                                            {d.logo.gatsbyImageData ? (
                                                <GatsbyImage
                                                    image={d.logo.gatsbyImageData}
                                                    alt={d.logo.title}
                                                    loading="lazy"
                                                />
                                            ) : (
                                                <img
                                                    src={d.logo.file.url}
                                                    alt={d.logo.title}
                                                    loading="lazy"
                                                />
                                            )}
                                        </span>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default DivisionDetail;

export const query = graphql`
    query divisionPageQuery($id: String, $relatedPages: [String]) {
        pageData: contentfulDivisions(id: { eq: $id }) {
            id
            name
            slug
            logo {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
                file {
                    url
                }
            }
            ogImage {
                gatsbyImageData(quality: 100)
            }
            featuredVideo
            description {
                description
            }
            members {
                id
                avatar {
                    gatsbyImageData(quality: 100, width: 54, placeholder: BLURRED)
                    title
                }
            }
            services {
                id
                title
                description {
                    description
                }
            }
            offices
            people
            years
            countries
            years
            website
            metaTitle
            metaDescription {
                metaDescription
            }
            casestudies {
                id
                name
                logo {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                    title
                }
                services {
                    id
                    title
                }
                description {
                    description
                }
                doneBy {
                    name
                }
                backgroundType
                backgroundMedia {
                    gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
                    title
                    file {
                        contentType
                        url
                    }
                }
                colorTheme
                thumbnailTheme
                slug
            }
        }
        otherDivisions: allContentfulDivisions(
            filter: {
                id: { in: $relatedPages }
                subDivision: { eq: false }
                showOnViewport: { eq: true }
            }
            limit: 3
        ) {
            nodes {
                name
                slug
                logo {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                    title
                    file {
                        url
                    }
                }
            }
        }
    }
`;
